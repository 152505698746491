import React from 'react';
import './style.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import web from '../../data/webData.json';
import Slider from './Slider';
import Audio from './Audio';
import Facebook from '../../components/Facebook';
import { NavLink } from 'react-router-dom';
//import GoogleAdscene from '../../components/GoogleAdscene';
import Video from './Video';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-86412422-1');
ReactGA.pageview(window.location.pathname + window.location.search);

const Home = (props) => {
	const Heading = web.data.Home.Heading;
	const content = web.data.Home.content;
	
	//console.log();
	return(
     <Container>
     	<Audio />
		<Row>
			<Slider />
		    <Col xs={12} sm={12} md={12} lg={12}>
		    	<div className="main-content">
		    		<Row>
		    			<Col xs={12} sm={12} md={12} lg={12}>
		    				<div className="mag-innert-left">
		    					<h1 className="title">{Heading}</h1>
		    					<Facebook /> 
		    					{
		    						content.map(content=>{
			    						return(
			    							<div key={content.id}>
			    								<p style={{margin:'0'}}>{content.paragraph}</p>
			    							</div>
			    						)
		    						})
		    					}
		    					<p><NavLink to="/about-mandir">Read more...</NavLink></p>
		    					<Video />
		    				</div>
		    				<div className="mag-innert-left marginTop3Em">
		    					<div className="fb-comments" data-href="http://maamundeshwari.org/" data-width="100%" data-numposts="10"></div><div className="clearfix"></div>
		    				</div>
		    			</Col>
		    		</Row>
		    	</div>
		    </Col>
		</Row>
	</Container>
	)
}		

export default Home