import React from 'react';
import './style.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import Col from 'react-bootstrap/Col';

const Menu = (props) => {
	return(
		<Col xs={12} sm={8} md={8} lg={8}>
	    	<Navbar collapseOnSelect expand="lg">
			  <Navbar.Brand href="#home"></Navbar.Brand>
			  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
			  <Navbar.Collapse id="responsive-navbar-nav">
			    <Nav className="mr-auto">
			     	<ul className="menu">
			     		<li><NavLink to="/">Home</NavLink></li>
			     		<li><NavLink to="/about-mandir">About</NavLink></li>
			     		<li><NavLink to="/history">History</NavLink></li>
			     		<li><NavLink to="/mythology">Mythology</NavLink></li>
			     		<li><NavLink to="/how-to-reach-maa-mundeshwari-mandir">How To Reach</NavLink></li>
			     		<li><NavLink to="/attraction-of-kaimur-bihar">Attraction</NavLink></li>
			     		<li><NavLink to="/disclaimer">Disclaimer</NavLink></li>
			     	</ul> 
			    </Nav>
			  </Navbar.Collapse>
			</Navbar>
	    </Col>
	)
}

export default Menu