import React from 'react';
import './style.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Facebook from '../../components/Facebook';
import web from '../../data/webData.json';

const About = (props) =>{
	const abtCont = web.data.About;

	return(
		<Container>
			<Row>
				<Col xs={12} sm={12} md={12} lg={12}>
					<div className="main-content">
						<div className="mag-innert-left">
							<Facebook />
							<div>
								{
									abtCont.map(abt=>{
										return(
											<div key={abt.id}>
												<h1 className="title">{abt.heading}</h1>
												<p>{abt.text}</p> 
											</div>
										)
									})	
								}
							</div>
						</div>
						<div className="mag-innert-left marginTop3Em">
	    					<div className="fb-comments" data-href="http://maamundeshwari.org/about-mandir.html" data-width="100%" data-numposts="10"></div><div className="clearfix"></div>
	    				</div>
					</div>
				</Col>
			</Row>
		</Container>
	)
}

export default About