import React from 'react';
import './style.css';
import webData from '../../../data/webData.json';
import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/Col';

const Slider = (props) => {
	const Img = webData.data.Home.Slider;
	//console.log(Img);
	return(
		<Col xs={12} sm={12} md={12} lg={12}>
			<Carousel>
				{
					Img.map(sliderImg =>{
						return(
							<Carousel.Item key={sliderImg.id}>
							    <img
							      className="d-block w-100"
							      src={require('../../../assets/slider/'+sliderImg.img)}
							      alt={sliderImg.alt}
							    />
							</Carousel.Item>
						)
					})
				}
			  
			</Carousel>
		</Col>
	)
}

export default Slider