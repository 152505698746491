import React from 'react';
import './style.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Logo from '../Logo';
import Menu from '../Menu';
import LanguageChange from './LanguageChange';
import SocialIcons from './SocialIcons';
const Header = (props) => {
	return(
			<header>
				<Container>
					<Row>
						<Logo />
						<Menu />
					    <Col xs={12} sm={3} md={3} lg={3}>
					    	<div className="textRight">
					    		<LanguageChange />
					    		<SocialIcons />
					    	</div>
					    </Col>
					    <div className="clearfix"></div>
					    <Col xs={12} sm={12} md={12} lg={12} >
					    	<div className="lineBorder"></div>
					    </Col>
					</Row>
				</Container>
			</header>
	)
}

export default Header