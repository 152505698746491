import React from 'react';
import { Form, FormGroup, Input, Label, Button } from 'reactstrap';
import './style.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Facebook from '../../components/Facebook';
import { emailjs } from 'emailjs-com';


class Contacts extends React.Component {
	constructor() {
		super();
		this.state ={
			name: "",
			mobile: "",
			email: "", 
			message: "",
		}
		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		
	}
	handleChange = e => {
		this.setState({ [e.target.name]: e.target.value });

	}
	
	handleSubmit = (e) => {
		e.preventDefault();
		//emailjs.send('<YOUR SERVICE ID>','<YOUR TEMPLATE ID>', templateParams, '<YOUR USER ID>')
		emailjs.sendFrom(
				"gmail",
				"pankajtemplate",
				".contact_form_class",
				"user_tGIE8CWLlD3SQVicVlGyl"
			)
			.than()
			.catch();
		const {name, mobile, email, message} = this.state
	}

	render(){
		return(
			<Container>
				<Row>
					<Col xs={12} sm={12} md={12} lg={12}>
						<div className="main-content">
							<div className="mag-innert-left">
								<Facebook />
								<h2 className="title">Contact Us</h2>
								<Row>
									<Col xs={12} sm={7} md={7} lg={7}>
										<div className="contact-form-box">
											<p>Hi this is <b>Pankaj Upadhyay</b> I am software engineer have 6+ Years of Experience <b>PHP, MySql, Codeigniter, Magento, HTML, CSS, JavaScript, Ajax, Payment Gateway.</b></p>
											<p><b>My Project</b></p>
											<p><b><a href="http://housemaids-services.in" target="_blank">housemaids-services.in</a> Skill Used : CodeIgniter, MySql, PHP, Jquery, HTML5, CSS3</b></p>
											<p><b><a href="http://maidservices9.com/" target="_blank">maidservices9.com</a> Skill Used : CodeIgniter, MySql, PHP, Ajax, Jquery, HTML5, CSS3</b></p>
											<p><b><a href="http://uniqueservices.co/" target="_blank">uniqueservices.co</a> Skill Used : CodeIgniter, MySql, PHP, Ajax, Jquery, HTML5, CSS3</b></p>
											<p><b><a href="http://www.astronomical.in/" target="_blank">astronomical.in</a> Skill Used : CodeIgniter, MySql, PHP, Jquery, HTML5, CSS3</b></p>
											<br />
											<p><a href="http://maamundeshwari.org/php-mysql-interview-question-and-answers.html"><b>PHP MySQL interview questions and answers</b></a></p>
											<div className="fb-follow" data-href="https://www.facebook.com/pankaj.upadhyay19" data-layout="button_count" data-size="small" data-show-faces="true"></div>
											<div className="fb-like" data-href="https://www.facebook.com/p.u1986" data-layout="button_count" data-action="like" data-size="small" data-show-faces="true" data-share="true"></div>
										</div>
									</Col>
									<Col xs={12} sm={5} md={5} lg={5}>
										<Form onSubmit={this.handleSubmit} style={{marginTop:'1em'}} className="contact_form_class">
											<FormGroup>
												<Input 
													type="text"
													name="name"
													placeholder="Full Name"
													onChange={this.handleChange}
												/>
											</FormGroup>
											<FormGroup>
												<Input 
													type="number"
													name="mobile"
													placeholder="Mobile"
													onChange={this.handleChange}
												/>
											</FormGroup>
											<FormGroup>
												<Input 
													type="email"
													name="email"
													placeholder="Email"
													onChange={this.handleChange}
												/>
											</FormGroup>
											<FormGroup>
												<Input 
													type="textarea"
													name="message"
													placeholder="Message"
													onChange={this.handleChange}
												/>
											</FormGroup>
											<Button>Submit</Button>
										</Form>
									</Col>
								</Row>
							</div>
							<div className="mag-innert-left marginTop3Em">
		    					<div className="fb-comments" data-href="http://maamundeshwari.org/contact-of-pankaj-upadhyay.php" data-width="100%" data-numposts="10"></div><div className="clearfix"></div>
		    				</div>
						</div>
					</Col>
				</Row>
			</Container>
		)
	}
}

export default Contacts