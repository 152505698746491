import React from 'react';
import './style.css';
import { NavLink } from 'react-router-dom';
import Col from 'react-bootstrap/Col';

const Logo = (props) => {
	return(
		<Col xs={12} sm={1} md={1} lg={1}>
			<NavLink to="/">
				<img src={require('../../assets/logo/logo.png')} alt="" />
			</NavLink>
		</Col>
	)
}

export default Logo