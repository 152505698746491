import React from 'react';
import './style.css';

const LanguageChange = (props) => {

	return(
		<div id="google_translate_element"></div>
	)
}

export default LanguageChange