import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import webVideo from '../../../data/webData.json';
import YouTube from 'react-youtube';

class Video extends React.Component{
	render(){
		const opts = {
	      height: '315',
	      width: '100%',
	      frameborder:'0',
	      playerVars: {
	        autoplay: 1,
	      },
	    };
		const videoData = webVideo.data.Home.videoLink;
		//console.log(videoData);
		return(
			<Row>
				{
					videoData.map(vd=>{
						return(
							<Col xs={12} sm={12} md={6} lg={6} key={vd.id}>
			    				<h2 className="title" style={{marginBottom:'2em'}}>{vd.heading}</h2>
			    				<YouTube videoId={vd.ytUrl} opts={opts} onReady={this._onReady} />;
			    			</Col>
						)
					})
				}
    		</Row>
		)
	}
	_onReady(event) {
	    event.target.pauseVideo();
	}
}

export default Video