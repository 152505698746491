import React from 'react';
import './style.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Facebook from '../../components/Facebook';

const Disclaimer = (props) => {
	return(
		<Container>
			<Row>
				<Col xs={12} sm={12} md={12} lg={12}>
					<div className="main-content">
						<div className="mag-innert-left">
							<Facebook />
							<div>
								<h1 className="title">DISCLAIMER</h1>
								<p>कृपया ध्यान दे ! यह माँ मुंडेश्वरी की आधिकारिक वेबसाइट नहीं है. इस वेबसाइट का उद्देस्य इस वेबसाइट से १ भी पैसे कमाना नहीं है. इस वेबसाइट का उद्देस्य सिर्फ माताजी का और हिन्दू धर्म का प्रचार करना है बाकि कुछ नहीं .</p>
							</div>
						</div>
						<div className="mag-innert-left marginTop3Em">
	    					<div className="fb-comments" data-href="http://maamundeshwari.org/history.html" data-width="100%" data-numposts="10"></div><div className="clearfix"></div>
	    				</div>
					</div>
				</Col>
			</Row>
		</Container>
	)
}

export default Disclaimer