import React from 'react';
import './style.css';
import {SocialMediaIconsReact} from 'social-media-icons-react';

const SocialIcons = (props) => {
	return(
		<div>
			<ul className="socialIcon">
				<li><SocialMediaIconsReact borderColor="rgba(0,0,0,.2)" borderWidth="0" 
				borderStyle="solid" icon="facebook" iconColor="rgba(255,255,255,1)" 
				backgroundColor="rgba(59,89,152,1)" iconSize="5" roundness="10%" 
				url="https://www.facebook.com/maamundeshwari/" size="34" /></li>
				<li><SocialMediaIconsReact borderColor="rgba(0,0,0,.2)" borderWidth="0" 
				borderStyle="solid" icon="twitter" iconColor="rgba(255,255,255,1)" 
				backgroundColor="rgba(85,172,238,1)" iconSize="5" roundness="10%" 
				url="https://twitter.com/maamundeshwari/" size="34" /></li>
				<li><SocialMediaIconsReact borderColor="rgba(0,0,0,.2)" borderWidth="0" 
				borderStyle="solid" icon="instagram" iconColor="rgba(255,255,255,1)" 
				backgroundColor="rgba(63,114,155,1)" iconSize="5" roundness="10%" 
				url="https://www.instagram.com/maamundeshwari/" size="34" /></li>
				<li><SocialMediaIconsReact borderColor="rgba(0,0,0,.2)" borderWidth="0" 
				borderStyle="solid" icon="youtube" iconColor="rgba(255,255,255,1)" 
				backgroundColor="rgba(220,39,37,1)" iconSize="5" roundness="10%" 
				url="https://www.youtube.com/channel/UCS2EI61Qrpl8KbIGkJXYVFw" size="34" /></li>
			</ul>
		</div>
	)
}
export default SocialIcons