import React from 'react';
import './style.css';
//import { FacebookProvider, ShareButton } from 'react-facebook';

const Facebook = (props) => {
	//const shareUrl = 'https://www.maamundeshwari.org/';
	return(
		<div className="fb-share-button" data-href="https://www.maamundeshwari.org/" data-layout="button_count" data-size="small"><a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.maamundeshwari.org%2F&amp;src=sdkpreparse" className="fb-xfbml-parse-ignore">Share</a></div>
	)
}

export default Facebook