import React from 'react';
import './App.css';
import Header from './components/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router,Route} from 'react-router-dom';
import Home from './containers/Home';
import About from './containers/About';
import History from './containers/History';
import Mela from './containers/Mela';
import Mythology from './containers/Mythology';
import HowToReach from './containers/HowToReach';
import Attraction from './containers/Attraction';
import Contacts from './containers/Contacts';
import Footer from './components/Footer';
import Disclaimer from './containers/Disclaimer';



function App() {
  return (
    <Router>
      <div className="App">
      <Header/>
      <Route exact path="/" component={Home} />
      <Route path="/about-mandir" component={About} />
      <Route path="/history" component={History} />
      <Route path="/maa-mundeshari-mela" component={Mela} />
      <Route path="/mythology" component={Mythology} />
      <Route path="/how-to-reach-maa-mundeshwari-mandir" component={HowToReach} />
      <Route path="/attraction-of-kaimur-bihar" component={Attraction} />
      <Route path="/contact-of-pankaj-upadhyay" component={Contacts} />
      <Route path="/disclaimer" component={Disclaimer} />
      <Footer />
    </div>
    </Router>
  )
}

export default App;
