import React from 'react';
import './style.css';
import { NavLink } from 'react-router-dom';
import  ReactDOM  from  'react-dom';

const Footer = (props) => {
	return(
		<div className="copyright">
			<p>&copy; 2020 Maamundeshwari.org <NavLink to="/contact-of-pankaj-upadhyay">Design and maintenance by Pankaj Upadhyay 08080107500</NavLink> | <NavLink to="/disclaimer">Disclaimer</NavLink></p>
		</div>
	)
}

export default Footer